<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.iso2_code"
            :external-errors="errors['iso2_code']"
            :label-form="'ISO2 Code' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.iso2_code"
            :disabled="disableCode"
            @change="$set(form, 'iso2_code', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.iso3_code"
            :external-errors="errors['iso3_code']"
            :label-form="'ISO3 Code' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.iso3_code"
            :disabled="!formEditable"
            @change="$set(form, 'iso3_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.numcode"
            :external-errors="errors['numcode']"
            :label-form="'ISO numeric' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.numcode"
            :disabled="!formEditable"
            @change="$set(form, 'numcode', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_cee"
            :external-errors="errors['is_cee']"
            :label-form="'CEE' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.is_cee"
            :disabled="!formEditable"
            @change="$set(form, 'is_cee', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.printable_name"
            :external-errors="errors['printable_name']"
            :label-form="'Printable Name' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.printable_name"
            :disabled="!formEditable"
            @change="$set(form, 'printable_name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.continent"
            :external-errors="errors['continent']"
            :label-form="'Continent' | translate"
            class-form="col-lg-6 mb-2"
            :disabled="!formEditable"
            label-search="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.continent ? { value: form.continent, text: form.continent_name } : null"
            :placeholder="$t('Select an option')"
            :options="continentListOptions"
            @select="selectContinent"
            @clear="clearContinent"
          />
          <form-thux-horizontal-select
            :validator="$v.form.admin_area"
            :external-errors="errors['admin_area']"
            :label-form="'Administrative area' | translate"
            class-form="col-lg-6 mb-2"
            :disabled="!formEditable"
            label-search="text"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.admin_area ? { value: form.admin_area, text: form.admin_area_name } : null"
            :placeholder="$t('Select an option')"
            :options="adminAreaListOptions"
            @select="selectAdminArea"
            @clear="clearAdminArea"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as COUNTRY_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ContinentMixin from './mixins/ContinentMixin'
import AdministrativeAreaMixin from './mixins/AdministrativeAreaMixin'

export default {
  name: 'CountryEdit',
  mixins: [ThuxDetailPanelMixin, ContinentMixin, AdministrativeAreaMixin],
  data () {
    return {
      pageName: 'Country',
      rolePerm: 'l10n_country_retrieve',
      editRolePerm: 'l10n_country_update'
    }
  },
  computed: {
    ...mapGetters({
      ...COUNTRY_TYPES.GENERIC.l10n.country.DETAIL.GETTERS
    }),
    disableCode () {
      if (this.formEditable) {
        if (!this.id) {
          return false
        }
      }
      return true
    }
  },
  components: { },
  methods: {
    ...mapActions({
      ...COUNTRY_TYPES.GENERIC.l10n.country.DETAIL.ACTIONS
    }),
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.id = formData.iso2_code
      if (!formData.is_cee) {
        formData.is_cee = false
      }
      return formData
    }
  },
  validations: {
    form: {
      iso2_code: { required },
      iso3_code: { required },
      is_cee: { },
      name: { required },
      printable_name: { required },
      numcode: { },
      continent: { required },
      admin_area: { }
    }
  }
}
</script>
