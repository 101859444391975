<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as COUNTRY_TYPES } from '../store'

export default {
  name: 'ContinentMixin',
  created () {
    if (!this.continentList || (this.continentList && this.continentList.length === 0)) {
      this.getContinentlist()
    }
  },
  computed: {
    ...mapGetters({
      continentList:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.GETTERS.continentList
    }),
    continentListOptions () {
      const options = []
      if (this.continentList && this.continentList.length > 0) {
        this.continentList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getContinentlist:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.ACTIONS.getContinentlist
    }),
    selectContinent (item) {
      this.$set(this.form, 'continent', item.value)
      this.$set(this.form, 'continent_name', item.text)
    },
    clearContinent () {
      this.$set(this.form, 'continent', null)
      this.$set(this.form, 'continent_name', null)
    }
  }
}
</script>
