<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as COUNTRY_TYPES } from './store'
import { ROUTES as COUNTRY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import CountryTable from './CountryTable'
import CountryCommonFilters from './CountryCommonFilters'
import CountryEdit from './CountryEdit.vue'

export default {
  name: 'CountryList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': CountryTable,
    'common-filters': CountryCommonFilters,
    'component-edit': CountryEdit,
    'component-detail': CountryEdit
  },
  data () {
    return {
      COUNTRY_ROUTES,
      title: this.$t('Countries'),
      rolePerm: ['l10n_country_list'],
      actionEnablePermission: ['l10n_country_enable'],
      actionDisablePermission: ['l10n_country_disable'],
      fieldName: 'iso2_code',
      searchFields: [
        { key: 'filter__iso2_code__icontains', placeholder: this.$t('ISO2 Code'), type: 'text', col: 6 },
        { key: 'filter__iso3_code__icontains', placeholder: this.$t('ISO 3 Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__printable_name__icontains', placeholder: this.$t('Printable Name'), type: 'text', col: 6 },
        {
          key: 'filter__is_cee',
          placeholder: this.$t('CEE'),
          type: 'boolean',
          col: 6,
          options: [
            { value: 'True', text: this.$t('Yes') },
            { value: 'False', text: this.$t('No') }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...COUNTRY_TYPES.GENERIC.l10n.country.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.COUNTRY_ROUTES.COUNTRY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('l10n_country_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: COUNTRY_TYPES.GENERIC.l10n.country.LIST.MUTATIONS.setSelectedList,
      setSelectAll: COUNTRY_TYPES.GENERIC.l10n.country.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...COUNTRY_TYPES.GENERIC.l10n.country.LIST.ACTIONS
    })
  }
}
</script>
