<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as COUNTRY_TYPES } from '../store'

export default {
  name: 'AdministrativeAreaMixin',
  created () {
    if (!this.administrativeAreaList || (this.administrativeAreaList && this.administrativeAreaList.length === 0)) {
      this.getAdministrativearealist()
    }
  },
  computed: {
    ...mapGetters({
      administrativeAreaList:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.GETTERS.administrativeAreaList
    }),
    adminAreaListOptions () {
      const options = []
      if (this.administrativeAreaList && this.administrativeAreaList.length > 0) {
        this.administrativeAreaList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getAdministrativearealist:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.ACTIONS.getAdministrativearealist
    }),
    selectAdminArea (item) {
      this.$set(this.form, 'admin_area', item.value)
      this.$set(this.form, 'admin_area_name', item.text)
    },
    clearAdminArea () {
      this.$set(this.form, 'admin_area', null)
      this.$set(this.form, 'admin_area_name', null)
    }
  }
}
</script>
